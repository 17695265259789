export default {
	maryOption: function () {
		return ['单身', '已婚', '同居', '离异', '其他']
	},
	nationalOption: function () {
		return ['中国', '澳洲', '新西兰', '英国', '美国', '日本', '韩国', '德国']
	},
	roomOption: function () {
		return [
			{
				values: ['1卧室', '2卧室', '3卧室', '4卧室', '5卧室', '6卧室', '7卧室', '8卧室', '9卧室', '10卧室'],
				defaultIndex: 1,
			},
			{
				values: ['1卫浴', '2卫浴', '3卫浴', '4卫浴', '5卫浴', '6卫浴', '7卫浴', '8卫浴', '9卫浴', '10卫浴'],
				defaultIndex: 1,
			},
			{
				values: ['1车位', '2车位', '3车位', '4车位', '5车位', '6车位', '7车位', '8车位', '9车位', '10车位'],
				defaultIndex: 1,
			},
		]
	},
	houseOption: function () {
		return ['Studio-套间', 'Apartment-公寓', 'Unit-单元房', 'House-别墅', 'Townhouse-联排别墅',]
	},
	AllOption: function () {
		return ['是', '否']
	},
	sourceOption: function () {
		return ['父母提供', '政府提供', '雇主提供']
	},
	shipOption: function () {
		return ['父亲', '母亲', '直系亲属', '非直系亲属']
	},
	emergencyOption: function () {
		return ['父母', '朋友', '亲属', '同事', '雇主', '女儿', '其他']
	},
	stayOption: function () {
		return ['与父母合住', '房从中介处', '租房从私人房东处', '居住在自己购买的房产', '分租']
	},
	WorkOption: function () {
		return ['未被雇佣', '被雇佣', '自由职业者', '退休']
	},
	proverOption: function () {
		return ['朋友', '亲属', '同事', '雇主', '女儿', '其他']
	},
	rentSexOption: function () {
		return ['男', '女', '无要求']
	},
	permitOption: function () {
		return ['允许', '不允许']
	},
	gradeOption: function () {
		return ['1st year','2nd year','3rd year','4th year','Postgard','Foundation','Undergraduate']
	}
}
