<template>
  <div style="background: #fff" :class="disabled ? 'disableGray' : ''">
    <van-notice-bar
      v-if="tipTxt"
      left-icon="info-o"
      wrapable
      :scrollable="false"
      :text="tipTxt"
    />
    <div class="free-header">
      <img src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/extendInfo/apply.png" width="100%" height="auto" />
    </div>
    <van-form @submit="onSubmit" :loading="loading">
      <div class="all-contaions">
        <div class="free-contaion">
          <div class="free-title">
            <div class="one">个人信息</div>
            <div class="two">(*为必填)</div>
          </div>
        </div>
        <van-cell-group>
          <van-field
            :left-icon="failIcon('firstName')"
            :disabled="disabled"
            v-model="infoFrom.firstName"
            required
            :rules="[{ required: true }]"
            label="姓"
            placeholder="请输入你的姓氏"
            right-icon="arrow"
          />
          <van-field
            :disabled="disabled"
            :left-icon="failIcon('lastName')"
            v-model="infoFrom.lastName"
            required
            :rules="[{ required: true }]"
            label="名"
            placeholder="请输入你的名称"
            right-icon="arrow"
          />
          <van-field
            :left-icon="failIcon('birthDay')"
            :disabled="disabled"
            :value="infoFrom.birthDay"
            readonly
            required
            :rules="[{ required: true }]"
            label="出生日期"
            placeholder="请选择你的出生日期"
            @click="isShow = !disabled"
            right-icon="notes-o"
          />
          <!-- 出生日期 -->
          <van-popup v-model="isShow" position="bottom">
            <van-datetime-picker
              type="date"
              v-model="currentDate"
              :min-date="minDate"
              :max-date="maxDate"
              title="选择年月日"
              v-if="isShow"
              @confirm="confirm"
              @cancel="isShow = false"
            />
          </van-popup>

          <!-- 性别 -->
          <div class="smoke-flex student">
            <div class="smoke-flex-left">
              <span>*</span>
              <van-icon :name="failIcon('sex')" />
              性别
            </div>
            <van-radio-group
              v-model="infoFrom.sex"
              direction="horizontal"
              :disabled="disabled"
            >
              <van-radio name="1" checked-color="#f67f58">男</van-radio>
              <van-radio name="2" checked-color="#f67f58">女</van-radio>
              <van-radio name="3" checked-color="#f67f58">不确定</van-radio>
            </van-radio-group>
          </div>
          <van-field
            :left-icon="failIcon('maritalStatus')"
            :disabled="disabled"
            :value="infoFroms.maryName"
            readonly
            required
            :rules="[{ required: true }]"
            label="婚姻状况"
            @click="isMary = !disabled"
            placeholder="请选择你的婚姻状况"
            right-icon="arrow"
          />
          <van-popup v-model="isMary" position="bottom">
            <van-picker
              show-toolbar
              :columns="maryOption"
              @cancel="isMary = false"
              @confirm="onConfirmMary"
            />
          </van-popup>
          <van-field
            :left-icon="failIcon('phone')"
            :disabled="disabled"
            v-model="infoFrom.phone"
            required
            type="tel"
            :rules="[
              { required: true },
              { pattern: /^[0-9]*$/, message: '手机号码格式错误！' },
            ]"
            label="手机号码"
            placeholder="请填写你的手机号码"
            right-icon="arrow"
          />
          <van-field
            :left-icon="failIcon('email')"
            :disabled="disabled"
            v-model="infoFrom.email"
            required
            type="text"
            :rules="[
              { required: true },
              {
                validator,
                message: '请输入正确的邮箱格式',
              },
            ]"
            label="邮箱"
            placeholder="请填写你的邮箱"
            right-icon="arrow"
          />
          <van-field
            :left-icon="failIcon('passport')"
            :disabled="disabled"
            v-model="infoFrom.passport"
            required
            :rules="[{ required: true }]"
            label="护照号码"
            placeholder="请填写你的护照号码"
            right-icon="arrow"
          />
          <van-field
            :left-icon="failIcon('expiryDayOfPassport')"
            :disabled="disabled"
            required
            :value="infoFrom.expiryDayOfPassport"
            readonly
            :rules="[{ required: true }]"
            label="护照过期日"
            placeholder="请选择你的护照过期日"
            @click="isPassport = !disabled"
            right-icon="notes-o"
          />
          <!-- 护照过期 -->
          <van-popup v-model="isPassport" position="bottom">
            <van-datetime-picker
              type="date"
              v-model="currentDate"
              :min-date="minDate"
              :max-date="maxDate"
              title="选择年月日"
              @confirm="confirmPass"
              @cancel="isPassport = false"
            />
          </van-popup>

          <!-- 是否吸烟 -->
          <div class="smoke-flex student">
            <div class="smoke-flex-left">
              <span>*</span>
              <van-icon :name="failIcon('whetherSmoking')" />
              是否吸烟
            </div>
            <van-radio-group
              v-model="infoFrom.whetherSmoking"
              direction="horizontal"
              :disabled="disabled"
            >
              <van-radio name="1" checked-color="#f67f58">是</van-radio>
              <van-radio name="2" checked-color="#f67f58">否</van-radio>
            </van-radio-group>
          </div>
          <div class="smoke-flex">
            <div class="smoke-flex-left">
              <span>*</span>
              <van-icon :name="failIcon('whetherStudent')" />
              是否学生
            </div>
            <van-radio-group
              v-model="infoFrom.whetherStudent"
              direction="horizontal"
              :disabled="disabled"
            >
              <van-radio name="1" checked-color="#f67f58">是</van-radio>
              <van-radio name="2" checked-color="#f67f58">否</van-radio>
            </van-radio-group>
          </div>
        </van-cell-group>
        <!-- 补充学生信息-->
        <div v-if="infoFrom.whetherStudent == 1">
          <div class="main-height"></div>
          <div class="free-contaion">
            <div class="free-title">
              <div class="one">经济情况-补充学生信息</div>
              <div class="two">(*为必填)</div>
            </div>
          </div>
          <van-cell-group>
            <van-field
              :left-icon="failIcon('schoolName')"
              :disabled="disabled"
              v-model="infoFrom.schoolName"
              required
              :rules="[{ required: true }]"
              label="学校名称 "
              placeholder="请填写你的学校"
              right-icon="arrow"
            />
            <van-field
              :left-icon="failIcon('studentNo')"
              :disabled="disabled"
              v-model="infoFrom.studentNo"
              required
              :rules="[{ required: true }]"
              label-width="100px"
              label="学生/入学号码"
              placeholder="请填写学生/入学号码"
              right-icon="arrow"
            />
            <van-field
              :left-icon="failIcon('professionalDirectorName')"
              :disabled="disabled"
              v-model="infoFrom.professionalDirectorName"
              required
              :rules="[{ required: true }]"
              label-width="110px"
              label="专业负责人姓名"
              placeholder="请填写专业负责人姓名"
              right-icon="arrow"
            />
            <van-field
              :left-icon="failIcon('professionalDirectorPhone')"
              :disabled="disabled"
              v-model="infoFrom.professionalDirectorPhone"
              required
              type="tel"
              :rules="[{ required: true }]"
              label-width="110px"
              label="负责人联系电话 "
              placeholder="请填写专业负责人联系电话"
              right-icon="arrow"
            />
          </van-cell-group>
        </div>
        <div class="main-height"></div>
        <!-- 补充学生信息end -->
        <div class="free-contaion">
          <div class="free-title">
            <div class="one">经济情况</div>
            <div class="two">(*为必填)</div>
          </div>
        </div>
        <van-cell-group>
          <van-field
            :left-icon="failIcon('financeSource')"
            :disabled="disabled"
            :value="infoFroms.sourceName"
            required
            readonly
            :rules="[{ required: true }]"
            label="经济来源"
            @click="isSource = !disabled"
            placeholder="请选择经济来源"
            right-icon="arrow"
          />
          <!-- 经济来源 -->
          <van-popup v-model="isSource" position="bottom">
            <van-picker
              show-toolbar
              :columns="sourceOption"
              @cancel="isSource = false"
              @confirm="onConfirmSource"
            />
          </van-popup>
          <div v-if="infoFrom.financeSource == 1">
            <van-field
              :left-icon="failIcon('financeRelationship')"
              :disabled="disabled"
              :value="infoFroms.relationshipName"
              readonly
              required
              :rules="[{ required: true }]"
              label="亲属关系"
              @click="isShip = !disabled"
              placeholder="请选择亲属关系"
              right-icon="arrow"
            />
            <!-- 亲属关系 -->
            <van-popup v-model="isShip" position="bottom">
              <van-picker
                show-toolbar
                :columns="shipOption"
                @cancel="isShip = false"
                @confirm="onConfirmShip"
              />
            </van-popup>
            <van-field
              :left-icon="failIcon('parentName')"
              :disabled="disabled"
              v-model="infoFrom.parentName"
              required
              :rules="[{ required: true }]"
              label-width="110px"
              label="父亲/母亲姓名"
              placeholder="请填写父亲/母亲姓名"
              right-icon="arrow"
            />
            <van-field
              :left-icon="failIcon('parentPhone')"
              :disabled="disabled"
              v-model="infoFrom.parentPhone"
              label-width="110px"
              required
              type="tel"
              :rules="[{ required: true }]"
              label="父亲/母亲电话"
              placeholder="请填写父亲/母亲电话"
              right-icon="arrow"
            />
            <van-field
              :left-icon="failIcon('weekAmountOfMoney')"
              :disabled="disabled"
              v-model="infoFrom.weekAmountOfMoney"
              type="number"
              required
              :rules="[{ required: true }]"
              label-width="140px"
              :label="weekMoney"
              placeholder="请填写可支配金额"
              right-icon="arrow"
            />
          </div>
        </van-cell-group>
        <div class="main-height"></div>
        <div class="free-contaion">
          <div class="free-title">
            <div class="one">紧急联系人</div>
            <div class="two">(*为必填)</div>
          </div>
        </div>
        <van-cell-group>
          <van-field
            :left-icon="failIcon('emergencyFullName')"
            :disabled="disabled"
            v-model="infoFrom.emergencyFullName"
            required
            :rules="[{ required: true }]"
            label="姓名"
            placeholder="请填写联系人姓名"
            right-icon="arrow"
          />
          <van-field
            :left-icon="failIcon('emergencyRelationship')"
            :disabled="disabled"
            :value="infoFroms.emergencyName"
            readonly
            required
            :rules="[{ required: true }]"
            label="关系"
            @click="isEmergency = !disabled"
            placeholder="请选择与联系人关系"
            right-icon="arrow"
          />
          <!-- 与联系人的关系 -->
          <van-popup v-model="isEmergency" position="bottom">
            <van-picker
              show-toolbar
              :columns="emergencyOption"
              @cancel="isEmergency = false"
              @confirm="onConfirmEmergency"
            />
          </van-popup>
          <van-field
            :left-icon="failIcon('emergencyPhone')"
            :disabled="disabled"
            v-model="infoFrom.emergencyPhone"
            type="tel"
            required
            :rules="[{ required: true }]"
            label="联系电话"
            placeholder="请填写联系电话"
            right-icon="arrow"
          />
          <van-field
            :left-icon="failIcon('emergencyAddress')"
            :disabled="disabled"
            v-model="infoFrom.emergencyAddress"
            required
            :rules="[{ required: true }]"
            label="居住地址"
            placeholder="请填写居住地址"
            right-icon="arrow"
          />
        </van-cell-group>
        <div class="main-height"></div>
        <div class="free-contaion">
          <div class="free-title">
            <div class="one">本地租房历史</div>
            <div class="two">(*为必填)</div>
          </div>
        </div>
        <van-cell-group>
          <van-field
            :left-icon="failIcon('currentStayingMethod')"
            :disabled="disabled"
            :value="infoFroms.currentStayName"
            readonly
            required
            :rules="[{ required: true }]"
            label="现居住方式"
            @click="isMethod = !disabled"
            placeholder="请选择现居住方式"
            right-icon="arrow"
          />
          <!-- 居住方式 -->
          <van-popup v-model="isMethod" position="bottom">
            <van-picker
              show-toolbar
              :columns="stayOption"
              @cancel="isMethod = false"
              @confirm="onConfirmMethod"
            />
          </van-popup>
          <van-field
            :left-icon="failIcon('currentAddress')"
            :disabled="disabled"
            v-model="infoFrom.currentAddress"
            required
            :rules="[{ required: true }]"
            label="现居住地址"
            placeholder="请填写居住地址"
            right-icon="arrow"
          />
          <van-field
            :left-icon="failIcon('currentCheckInTime')"
            :disabled="disabled"
            :value="infoFrom.currentCheckInTime"
            readonly
            required
            :rules="[{ required: true }]"
            label="入住日期"
            placeholder="请选择你的入住"
            @click="isInDate = !disabled"
            right-icon="notes-o"
          />
          <!-- 入住日期 -->
          <van-popup v-model="isInDate" position="bottom">
            <van-datetime-picker
              type="date"
              v-model="currentDate"
              :min-date="minDate"
              :max-date="maxDate"
              title="选择年月日"
              @confirm="confirmInDate"
              @cancel="isInDate = false"
            />
          </van-popup>
          <van-cell
            required
            :rules="[{ required: true }]"
            is-link
            class="van-cell-tag"
          >
            <van-field
              :left-icon="failIcon('moneyOfMonthly')"
              :disabled="disabled"
              v-model="infoFrom.moneyOfMonthly"
              type="number"
              placeholder="请输入租金金额"
            ></van-field>
            <template #title>
              <span class="custom-title">
                <van-icon :name="failIcon('moneyOfMonthly')" />
                每月房租金额</span
              >
              <van-tag class="custom-tag" type="warning">{{
                monetarySymbol
              }}</van-tag>
            </template>
          </van-cell>
          <van-field
            :left-icon="failIcon('currentMoveReason')"
            :disabled="disabled"
            v-model="infoFrom.currentMoveReason"
            required
            :rules="[{ required: true }]"
            label="搬走原因"
            placeholder="请填写搬走原因"
            right-icon="arrow"
          />
          <van-field
            :left-icon="failIcon('currentOwnerName')"
            :disabled="disabled"
            v-model="infoFrom.currentOwnerName"
            required
            :rules="[{ required: true }]"
            label="中介/房东姓名"
            label-width="100px"
            placeholder="请填写中介/房东姓"
            right-icon="arrow"
          />
          <van-field
            :left-icon="failIcon('currentOwnerPhone')"
            :disabled="disabled"
            v-model="infoFrom.currentOwnerPhone"
            type="tel"
            required
            :rules="[{ required: true }]"
            label="中介/房东电话"
            label-width="100px"
            placeholder="请填写中介/房东电话"
            right-icon="arrow"
          />
          <van-field
            :left-icon="failIcon('currentOwnerEmail')"
            :disabled="disabled"
            v-model="infoFrom.currentOwnerEmail"
            type="text"
            required
            :rules="[
              { required: true },
              {
                validator,
                message: '请输入正确的邮箱格式',
              },
            ]"
            label="中介/房东邮箱"
            label-width="100px"
            placeholder="请填写中介/房东邮箱"
            right-icon="arrow"
          />

          <!-- 押金是否退还 -->
          <div class="smoke-flex">
            <div class="smoke-flex-left">
              <span>*</span>
              <van-icon :name="failIcon('refundedBondFull')" />
              此次押金是否已全额退还
            </div>
            <van-radio-group
              v-model="infoFrom.refundedBondFull"
              direction="horizontal"
              :disabled="disabled"
            >
              <van-radio name="1" checked-color="#f67f58">是</van-radio>
              <van-radio name="2" checked-color="#f67f58">否</van-radio>
            </van-radio-group>
          </div>
        </van-cell-group>
        <div v-if="infoFrom.refundedBondFull == 2">
          <van-cell-group>
            <van-cell
              :icon="failIcon('refundedBondFullReason')"
              title="请填写未退还原因"
              required
            />
            <van-field
              :disabled="disabled"
              v-model="infoFrom.refundedBondFullReason"
              :rules="[{ required: true }]"
              rows="1"
              autosize
              type="textarea"
              maxlength="500"
              placeholder="请填写未退还原因"
              show-word-limit
            />
          </van-cell-group>
        </div>
        <div class="main-height"></div>
        <div class="free-contaion">
          <div class="free-title">
            <div class="one">就业状态</div>
            <div class="two">(*为必填)</div>
          </div>
        </div>
        <van-cell-group>
          <!-- 当前就业状态 -->
          <div class="smoke-flex">
            <div class="smoke-flex-left mr">
              <span>*</span>
              <van-icon :name="failIcon('workStatus')" />
              就业状态
            </div>
            <van-radio-group
              v-model="infoFrom.workStatus"
              direction="horizontal"
              :disabled="disabled"
            >
              <van-radio name="1" checked-color="#f67f58">未被雇佣</van-radio>
              <van-radio name="2" checked-color="#f67f58">被雇佣</van-radio>
              <van-radio name="4" checked-color="#f67f58">退休</van-radio>
              <van-radio name="3" checked-color="#f67f58">自由职业者</van-radio>
            </van-radio-group>
          </div>

          <div v-if="infoFrom.workStatus == 2">
            <!-- 工作状态 -->
            <div class="smoke-flex mr-student">
              <div class="smoke-flex-left mr">
                <span>*</span>
                <van-icon :name="failIcon('workingStatus')" />
                工作状态
              </div>
              <van-radio-group
                v-model="infoFrom.workingStatus"
                direction="horizontal"
                :disabled="disabled"
              >
                <van-radio name="1" checked-color="#f67f58">全职</van-radio>
                <van-radio name="2" checked-color="#f67f58">实习</van-radio>
                <van-radio name="3" checked-color="#f67f58">兼职</van-radio>
                <van-radio name="4" checked-color="#f67f58">自由</van-radio>
              </van-radio-group>
            </div>
            <van-field
              :left-icon="failIcon('yearIncome')"
              :disabled="disabled"
              v-model="infoFrom.yearIncome"
              required
              type="number"
              :rules="[{ required: true }]"
              label-width="140px"
              :label="yearIncomes"
              placeholder="请填写年均收入"
              right-icon="arrow"
            />
            <van-field
              :left-icon="failIcon('companyName')"
              :disabled="disabled"
              v-model="infoFrom.companyName"
              required
              :rules="[{ required: true }]"
              label="就职公司名称"
              placeholder="请填写公司名称"
              right-icon="arrow"
            />
            <van-field
              :left-icon="failIcon('companyContactsName')"
              :disabled="disabled"
              v-model="infoFrom.companyContactsName"
              required
              :rules="[{ required: true }]"
              label-width="110px"
              label="公司联系人姓名"
              placeholder="请填写公司联系人姓名"
              right-icon="arrow"
            />
            <van-field
              :left-icon="failIcon('companyContactsPhone')"
              :disabled="disabled"
              v-model="infoFrom.companyContactsPhone"
              required
              type="tel"
              :rules="[{ required: true }]"
              label="公司联系电话"
              placeholder="请填写公司电话"
              right-icon="arrow"
            />
            <van-field
              :left-icon="failIcon('companyEmail')"
              :disabled="disabled"
              v-model="infoFrom.companyEmail"
              required
              type="text"
              :rules="[
                { required: true },
                {
                  validator,
                  message: '请输入正确的邮箱格式',
                },
              ]"
              label="公司邮箱"
              placeholder="请填写公司邮箱"
              right-icon="arrow"
            />
            <van-field
              :left-icon="failIcon('companyAdress')"
              :disabled="disabled"
              v-model="infoFrom.companyAdress"
              required
              :rules="[{ required: true }]"
              label="公司地址"
              placeholder="请填写公司地址"
              right-icon="arrow"
            />
            <van-field
              :left-icon="failIcon('companyPosition')"
              :disabled="disabled"
              v-model="infoFrom.companyPosition"
              required
              :rules="[{ required: true }]"
              label-width="110px"
              label="你的职业/职位"
              placeholder="请填写职业/职位"
              right-icon="arrow"
            />
          </div>
        </van-cell-group>
        <div class="main-height"></div>
        <div class="free-contaion">
          <div class="free-title">
            <div class="one">相关证明人</div>
            <div class="two">(*为必填)</div>
          </div>
        </div>
        <van-cell-group>
          <van-field
            :left-icon="failIcon('proverName')"
            :disabled="disabled"
            v-model="infoFrom.proverName"
            required
            :rules="[{ required: true }]"
            label="TA的姓名"
            placeholder="请填写联系人姓名"
            right-icon="arrow"
          />
          <van-field
            :left-icon="failIcon('contactsOneRelationship')"
            :disabled="disabled"
            :value="infoFroms.proverRelationshipName"
            readonly
            required
            :rules="[{ required: true }]"
            label-width="100px"
            label="TA与你的关系"
            @click="isProver = !disabled"
            placeholder="请选择与联系人关系"
            right-icon="arrow"
          />
          <!-- TA与你的关系 -->
          <van-popup v-model="isProver" position="bottom">
            <van-picker
              show-toolbar
              :columns="proverOption"
              @cancel="isProver = false"
              @confirm="onConfirmProver"
            />
          </van-popup>
          <van-field
            :left-icon="failIcon('proverPhone')"
            :disabled="disabled"
            v-model="infoFrom.proverPhone"
            type="tel"
            required
            :rules="[{ required: true }]"
            label-width="100px"
            label="TA的联系电话"
            placeholder="请填写联系电话"
            right-icon="arrow"
          />
          <van-field
            :left-icon="failIcon('proverEmail')"
            :disabled="disabled"
            v-model="infoFrom.proverEmail"
            type="text"
            label="TA的邮箱"
            placeholder="请填写TA的邮箱"
            :rules="[
              { required: false },
              { validator, message: '请输入正确的邮箱格式' },
            ]"
            right-icon="arrow"
          />
        </van-cell-group>
        <div class="main-height"></div>
        <div class="free-contaion">
          <div class="free-title">
            <div class="one">相关联系人1</div>
            <div class="two">(*为必填)</div>
          </div>
        </div>
        <van-cell-group>
          <van-field
            :left-icon="failIcon('contactsOneName')"
            :disabled="disabled"
            v-model="infoFrom.contactsOneName"
            required
            :rules="[{ required: true }]"
            label="TA的姓名"
            placeholder="请填写联系人姓名"
            right-icon="arrow"
          />
          <van-field
            :left-icon="failIcon('contactsOneRelationship')"
            :disabled="disabled"
            :value="infoFroms.contactsOneRelationshipName"
            readonly
            required
            :rules="[{ required: true }]"
            label-width="100px"
            label="TA与你的关系"
            @click="isConcat = !disabled"
            placeholder="请选择与联系人关系"
            right-icon="arrow"
          />
          <!-- 与联系人关系 -->
          <van-popup v-model="isConcat" position="bottom">
            <van-picker
              show-toolbar
              :columns="proverOption"
              @cancel="isConcat = false"
              @confirm="onConfirmConcat"
            />
          </van-popup>

          <van-field
            :left-icon="failIcon('contactsOnePhone')"
            :disabled="disabled"
            v-model="infoFrom.contactsOnePhone"
            type="tel"
            required
            :rules="[{ required: true }]"
            label-width="100px"
            label="TA的联系电话"
            placeholder="请填写联系电话"
            right-icon="arrow"
          />
          <van-field
            :left-icon="failIcon('contactsOneEmail')"
            :disabled="disabled"
            v-model="infoFrom.contactsOneEmail"
            type="text"
            label="TA的邮箱"
            placeholder="请填写TA的邮箱"
            :rules="[
              { required: false },
              {
                validator,
                message: '请输入正确的邮箱格式',
              },
            ]"
            right-icon="arrow"
          />
        </van-cell-group>
        <div class="main-height"></div>
        <div class="free-contaion">
          <div class="free-title">
            <div class="one">相关联系人2</div>
            <div class="two">(*为必填)</div>
          </div>
        </div>
        <van-cell-group>
          <van-field
            :left-icon="failIcon('contactsTwoName')"
            :disabled="disabled"
            v-model="infoFrom.contactsTwoName"
            required
            :rules="[{ required: true }]"
            label="TA的姓名"
            placeholder="请填写联系人姓名"
            right-icon="arrow"
          />
          <van-field
            :left-icon="failIcon('contactsTwoRelationship')"
            :disabled="disabled"
            :value="infoFroms.contactsTwoRelationshipName"
            readonly
            required
            :rules="[{ required: true }]"
            label-width="100px"
            label="TA与你的关系"
            @click="isConcatTwo = !disabled"
            placeholder="请选择与联系人关系"
            right-icon="arrow"
          />
          <!-- 与联系人关系 -->
          <van-popup v-model="isConcatTwo" position="bottom">
            <van-picker
              show-toolbar
              :columns="proverOption"
              @cancel="isConcatTwo = false"
              @confirm="onConfirmConcatTwo"
            />
          </van-popup>
          <van-field
            :left-icon="failIcon('contactsTwoPhone')"
            :disabled="disabled"
            v-model="infoFrom.contactsTwoPhone"
            type="tel"
            required
            :rules="[{ required: true }]"
            label-width="100px"
            label="TA的联系电话"
            placeholder="请填写联系电话"
            right-icon="arrow"
          />
          <van-field
            :left-icon="failIcon('contactsTwoEmail')"
            :disabled="disabled"
            v-model="infoFrom.contactsTwoEmail"
            type="text"
            label="TA的邮箱"
            placeholder="请填写TA的邮箱"
            :rules="[
              {
                validator,
                message: '请输入正确的邮箱格式',
              },
            ]"
            right-icon="arrow"
          />
        </van-cell-group>
        <van-checkbox
          v-if="!disabled"
          class="message"
          checked-color="#F67F58"
          v-model="checked"
          :disabled="disabled"
          >【真实性承若】我承诺以上信息真实有效可以用于ClozHome易享家为我申请房源使用</van-checkbox
        >
        <div class="message">
          如遇到问题请至邮件info@clozhome或您的顾问获取帮助
        </div>
      </div>
      <div class="spacing-height"></div>
      <div class="bottom-flex" v-if="!disabled">
        <div class="flex-left">
          <div class="left-consult" @click="serviceClick">
            <van-image
              width="23"
              height="20"
              fit="scale-down"
              src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/img/consult.png"
            /><br />咨询客服
          </div>
        </div>
        <div class="flex-right">
          <van-button v-if="disabled" class="default" type="default"
            >立即提交</van-button
          >
          <van-button
            v-if="!disabled"
            class="primary"
            type="primary"
            native-type="submit"
            >立即提交</van-button
          >
        </div>
      </div>
    </van-form>
  </div>
</template>
<script>
import {
  addUpdateApply,
  getUpdateApplyStatus,
  getProductUpdateInfo,
} from "@/api/informations/information";
import { timeStampChange, transform } from "@/utils/index";
// import wx from 'weixin-js-sdk'
// var wx = require("weixin-js-sdk");

import Data from "@/utils/options";
export default {
  name: "FreeApplication",
  data() {
    return {
      infoFrom: {},
      infoFroms: {},
      product: {},
      maryOption: Data.maryOption(),
      sourceOption: Data.sourceOption(), //经济来源
      shipOption: Data.shipOption(), //亲属关系
      emergencyOption: Data.emergencyOption(), //与紧急联系人关系
      stayOption: Data.stayOption(), //现居住方式
      proverOption: Data.proverOption(), //相关联系人
      minDate: new Date(1910, 0, 1),
      maxDate: new Date(2080, 10, 1),
      currentDate: new Date(),
      isShow: false,
      isPassport: false,
      isMary: false,
      isSource: false,
      isShip: false,
      isEmergency: false,
      isInDate: false,
      isMethod: false,
      isProver: false,
      isConcat: false,
      isConcatTwo: false,
      checked: false,
      processId: undefined,
      loading: true,
      disabled: false,
      monetarySymbol: "AUD",
      env: "",
      tipTxt: "",
      failLabels: [],
    };
  },
  created() {
    this.processId = this.$route.query.processId;
    this.onload();
  },
  mounted() {
    let that = this;
    this.$nextTick(() => {
      document.addEventListener("UniAppJSBridgeReady", function () {
        uni.getEnv(function (res) {
          that.env = res;
          console.log("当前环境：" + JSON.stringify(res));
        });
      });
    });
  },
  computed: {
    weekMoney() {
      return `每周可支配金额(单位: ${this.monetarySymbol})`;
    },
    yearIncomes() {
      return `年均收入(单位: ${this.monetarySymbol})`;
    },
  },
  methods: {
    failIcon(label) {
      for (let i = 0; i < this.failLabels.length; i++) {
        if (this.failLabels[i] == label) {
          return "info-o";
        }
      }
      return "";
    },
    //验证邮箱
    validator(val) {
      console.log("验证邮箱结果===>", val);
      if (!val) return true;
      if (val.trim().length == 0) return true;
      return /^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/.test(
        val
      );
    },
    //验证邮箱...
    onload() {
      // this.disabled = true;
      getUpdateApplyStatus({ processId: this.processId }).then((res) => {
        // this.loading = false;
        if (res.data) {
          this.monetarySymbol = res.data.monetarySymbol;
          if (res.data.status == 1) {
            if (res.data.failItems) {
              //被驳回了
              this.disabled = false;
              this.tipTxt =
                "您好，您提供的补充申请信息中" +
                this.assemblyTip(res.data.failItems) +
                "信息有误,请确认信息修改后重新提交!";
            } else {
              this.disabled = true;
            }
            this.infoFrom = JSON.parse(res.data.updateInfo);
            let data = this.infoFrom;
            this.infoFroms.maryName = transform(
              this.maryOption,
              data.maritalStatus
            );
            this.infoFroms.sourceName = transform(
              this.sourceOption,
              data.financeSource
            );
            this.infoFroms.relationshipName = transform(
              this.shipOption,
              data.financeRelationship
            );
            this.infoFroms.emergencyName = transform(
              this.emergencyOption,
              data.emergencyRelationship
            );
            this.infoFroms.currentStayName = transform(
              this.stayOption,
              data.currentStayingMethod
            );
            this.infoFroms.proverRelationshipName = transform(
              this.proverOption,
              data.proverRelationship
            );
            this.infoFroms.contactsOneRelationshipName = transform(
              this.proverOption,
              data.contactsOneRelationship
            );
            this.infoFroms.contactsTwoRelationshipName = transform(
              this.proverOption,
              data.contactsTwoRelationship
            );
          }
          this.getProductUpdateInfo(res.data.productId);
        }
      });
    },
    //组装驳回字段,超过3个...
    assemblyTip(failItems) {
      let txtAry = [];
      if (failItems) {
        let itemsAry = failItems.split(",");
        itemsAry.map((it) => {
          let item = it.split("=");
          this.failLabels.push(item[0]);
          txtAry.push(item[1]);
        });
      }
      if (txtAry.length > 3) {
        return txtAry[0] + "," + txtAry[1] + "," + txtAry[2] + "...";
      }
      return txtAry.join(",");
    },
    getProductUpdateInfo(productId) {
      getProductUpdateInfo({ productId: productId }).then((res) => {
        this.product = res.data;
      });
    },
    confirm(data) {
      this.infoFrom.birthDay = timeStampChange(data);
      this.isShow = false;
    },
    onConfirmMary(value, index) {
      this.infoFroms.maryName = value;
      this.infoFrom.maritalStatus = index + 1;
      this.isMary = false;
    },
    confirmPass(data) {
      this.infoFrom.expiryDayOfPassport = timeStampChange(data);
      this.isPassport = false;
    },
    onConfirmSource(value, index) {
      this.infoFroms.sourceName = value;
      this.infoFrom.financeSource = index + 1;
      this.isSource = false;
    },
    onConfirmShip(value, index) {
      this.infoFroms.relationshipName = value;
      this.infoFrom.financeRelationship = index + 1;
      this.isShip = false;
    },
    onConfirmEmergency(value, index) {
      this.infoFrom.emergencyRelationship = index + 1;
      this.infoFroms.emergencyName = value;
      this.isEmergency = false;
    },
    confirmInDate(data) {
      this.infoFrom.currentCheckInTime = timeStampChange(data);
      this.isInDate = false;
    },
    onConfirmMethod(value, index) {
      this.infoFrom.currentStayingMethod = index + 1;
      this.infoFroms.currentStayName = value;
      this.isMethod = false;
    },
    onConfirmProver(value, index) {
      this.infoFroms.proverRelationshipName = value;
      this.infoFrom.proverRelationship = index + 1;
      this.isProver = false;
    },
    onConfirmConcat(value, index) {
      this.infoFroms.contactsOneRelationshipName = value;
      this.infoFrom.contactsOneRelationship = index + 1;
      this.isConcat = false;
    },
    onConfirmConcatTwo(value, index) {
      this.infoFroms.contactsTwoRelationshipName = value;
      this.infoFrom.contactsTwoRelationship = index + 1;
      this.isConcatTwo = false;
    },
    serviceClick() {
      console.log("点击联系客服===>");
      if (this.env.miniprogram) {
        uni.navigateTo({
          url: "/otherPages/webview/showContact",
        });
      } else {
        enterMeiQiaService.postMessage(
          JSON.stringify({
            serviceName: this.product.productName,
            applyType: this.product.updateApplyName,
          })
        );
      }
    },
    // 提交接口
    onSubmit() {
      var that = this;
      console.log("点击提交===>");
      if (this.disabled) {
        return;
      }
      if (!this.checked) {
        this.$failToast("请勾选真实性承诺");
        return;
      }
      if (this.infoFrom.whetherStudent === "2") {
        delete this.infoFrom.schoolName;
        delete this.infoFrom.studentNo;
        delete this.infoFrom.professionalDirectorName;
        delete this.infoFrom.professionalDirectorPhone;
      }
      if (this.infoFrom.financeSource === 2) {
        delete this.infoFrom.financeRelationship;
        delete this.infoFrom.parentName;
        delete this.infoFrom.parentPhone;
        delete this.infoFrom.weekAmountOfMoney;
      }
      let obj = {
        updateApplyJson: JSON.stringify(this.infoFrom),
        processId: this.processId,
      };
      addUpdateApply(obj).then((res) => {
        console.log("提交补充表单结果===>", res);
        if (res.code == 0) {
          var obj2 = JSON.stringify({
            orderNo: res.data.orderNo,
            orderTime: res.data.orderTime,
            recordId: res.data.recordId,
          });
          var obj3 = encodeURIComponent(obj2);
          // if (that.env.miniprogram) {
          //   uni.navigateTo({
          //     url: `/applyPages/extendInfo?orders=${obj3}`,
          //   });
          // } else {
          //   enterApplyResult.postMessage(
          //     JSON.stringify({
          //       orderNo: res.data.orderNo,
          //       orderTime: res.data.orderTime,
          //       recordId: res.data.recordId,
          //     })
          //   );
          // }
          //
          try {
            enterApplyResult.postMessage(
              JSON.stringify({
                orderNo: res.data.orderNo,
                orderTime: res.data.orderTime,
                recordId: res.data.recordId,
              })
            );
          } catch (error) {
            uni.navigateTo({
              url: `/applyPages/extendInfo?orders=${obj3}`,
            });
          }
          //
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
/deep/.van-icon-info-o {
  color: #ed6a0c;
  font-size: 16px !important;
}
.bottom-flex {
  padding-top: 10px;
  padding-bottom: 10px;
}
.main-height {
  height: 10px;
}
/deep/.van-icon-notes-o::before {
  font-size: 22px;
}
.custom-tag {
  margin-left: 10px;
}

.van-cell-tag {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/deep/.van-checkbox {
  margin: 20px 0;
  font-size: 12px;
}

/deep/.van-checkbox__label {
  color: #f67f58;
}
/deep/.van-button {
  height: 40px;
  width: 100%;
  color: #fff;
  font-size: 16px;
  letter-spacing: 1px;
}
/deep/.van-radio--horizontal {
  margin-top: 10px;
}
.flex-right {
  border-radius: 4px;
  overflow: hidden;
}
.disableGray {
  .smoke-flex-left {
    color: #c8c9cc;
  }
}
</style>
